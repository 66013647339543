<div class="wrapper-modal">
  <form [formGroup]="createPortfolioForm" (ngSubmit)="submitCreatePortfolioForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">New portfolio</span>
    </div>
    <div class="content-modal">
      <div class="wrapper-input">
        <span class="label-input">Client Name</span>
        <div class="zc-input">
          <input type="text" [formControl]="createPortfolioForm.controls.client_name | formControl">
        </div>
        <span class="error-message">{{createPortfolioForm.controls.client_name | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-input">
        <span class="label-input">Portfolio Name</span>
        <div class="zc-input">
          <input type="text" [formControl]="createPortfolioForm.controls.name | formControl">
        </div>
        <span class="error-message">{{createPortfolioForm.controls.name | formControlErrorMessages: null : formTriedToSubmit}}</span>
      </div>
      <br>
      <span></span>
    </div>
    <div class="footer-modal">
      <button type="button" class="zc-btn zc-btn--gray" (click)="cancelModal.emit()"><span>Cancel</span></button>
      <button type="submit" class="zc-btn zc-btn--primary zc-position-relative">
        <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
        <span>Confirm</span>
      </button>
    </div>
  </form>
</div>
