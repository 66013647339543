import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {DocumentsHttpService} from '../../../../../services/http-services/documents/documents-http.service';
import {ToastrService} from 'ngx-toastr';
import {ChatRole} from '../../../../../enums/document_enums';
import {DomSanitizer} from '@angular/platform-browser';
import {ChatMessage} from '../../../../../services/http-services/documents/documents-http-interfaces';
import {AuthenticationHelperService} from "../../../../../services/helpers/authentication-helper.service";
import {fadeInOutAnimation} from "../../../../../animations/FadeInOutAnimation";

@Component({
  selector: 'wx-document-chat',
  templateUrl: './document-chat.component.html',
  animations: [fadeInOutAnimation]
})

export class DocumentChatComponent implements OnChanges {
  @Input() documentData: any;
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  isShowInitialPrompts = true;
  isDragOver = false;
  dragCounter = 0;
  chatMessages: ChatMessage[] = [];
  chatInput = '';
  bodyParams: any = {
    document_id: undefined,
    prompt: undefined,
    user_ask: undefined,
    file: undefined,
    additional_info: undefined,
  };
  previewFileURL = '';
  isWaitingForAnswer = false;
  isChatConversationLimitReached = false;
  protected readonly ChatRole = ChatRole;

  constructor(private sanitizer: DomSanitizer,
              private toasterHelperService: ToastrService,
              public authenticationHelperService: AuthenticationHelperService,
              public  documentsHttpService: DocumentsHttpService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documentData && changes.documentData.currentValue) {
      this.isChatConversationLimitReached = changes.documentData.currentValue.is_completed === 1;
      this.bodyParams.document_id = changes.documentData.currentValue.id;
      this.chatMessages = changes.documentData.currentValue.messages;
      if (this.chatMessages.length) {
        this.isShowInitialPrompts = false;
        if (this.chatMessages[0].content.content === this.documentData.prompt_first) {
          this.bodyParams.prompt = this.documentData.prompt_first;
        }
        this.chatMessages = this.chatMessages.map((message: any) => {
          if (message.file_path && message.file_name.slice(-3) !== 'pdf') {
            this.documentsHttpService.returnBase64FromImage(message.file_path).then((imageResponse: string) => {
              message.filePreviewUrl = imageResponse;
            });
          }
          return message;
        });
        setTimeout(() => this.scrollToBottomOfChatConversation(), 0);
      } else {
        this.onSelectPrompt(3);
      }
    }
  }

  onSelectPrompt(promptId: number): void {
    this.bodyParams.prompt = promptId;
    this.bodyParams.user_ask = 'Please summarize contents from PDF document and provide financial insights based on contents. Suggest questions that I could ask you about the document.';
    this.isShowInitialPrompts = false;
    this.sendQuestionToOpenAi();
  }

  onFileChange(event: any): void {
    if (!this.isWaitingForAnswer && this.chatMessages.length && !this.isChatConversationLimitReached) {
      const file = event.target.files[0];
      if (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
        this.toasterHelperService.warning('Invalid file type. Only PDF, JPG, JPEG, and PNG are allowed.');
        return;
      } else if (file?.size > 10485760) {
        this.toasterHelperService.warning('File larger than 10MB');
        return;
      } else {
        this.bodyParams.file = event.target.files[0];
        this.previewFile(this.bodyParams.file);
      }
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    this.dragCounter++;
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.isDragOver = false;
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.dragCounter--;
    this.isDragOver = false;
    if (!this.isWaitingForAnswer && this.chatMessages.length && !this.isChatConversationLimitReached) {
      const file = event.dataTransfer?.files[0];
      if (file) {
        this.bodyParams.file = file;
        if (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
          this.toasterHelperService.warning('Invalid file type. Only PDF, JPG, JPEG, and PNG are allowed.');
          return;
        } else if (file?.size > 10485760) {
          this.toasterHelperService.warning('File larger than 10MB');
          return;
        } else {
          this.bodyParams.file = file;
          this.previewFile(this.bodyParams.file);
        }
      }
    }
  }

  removeFile(): void {
    this.bodyParams.file = undefined;
    this.fileInput.nativeElement.value = '';
  }

  sendQuestionToOpenAi(): void {
    if (this.chatMessages.length) {
      this.isWaitingForAnswer = true;
    }
    const formData: FormData = new FormData();
    Object.keys(this.bodyParams).forEach((key: string) => {
      if (this.bodyParams[key] !== undefined) {
        formData.append(key, this.bodyParams[key]);
      }
    });
    this.bodyParams.user_ask = undefined;
    this.bodyParams.file = undefined;
    this.documentsHttpService.postSendQuestion(formData).then(res => {
      this.chatMessages = res.messages.map((message: any) => {
        if (message.file_path && message.file_name.slice(-3) !== 'pdf') {
          this.documentsHttpService.returnBase64FromImage(message.file_path).then(imageResponse => {
            message.filePreviewUrl = imageResponse;
          })
        }
        return message;
      });
      setTimeout(() => this.scrollToBottomOfChatConversation(), 0);
      this.isWaitingForAnswer = false;
    }).catch((error) => {
      if (error.error.message === 'You reached maximum number of questions') {
        this.isChatConversationLimitReached = true;
        this.isWaitingForAnswer = false;
      };
    });
  }

  resetDocumentChat(): void {
    this.documentsHttpService.postChatReset({document_id: this.documentData.id}).then(res => {
      this.chatMessages = [];
      this.isShowInitialPrompts = true;
      this.toasterHelperService.success('Document chat reset successfully.')

      this.bodyParams.prompt = 3;
      this.bodyParams.user_ask = 'Please summarize contents from PDF document and provide financial insights based on contents. Suggest questions that I could ask you about the document.';
      this.isShowInitialPrompts = false;
      this.sendQuestionToOpenAi();
    });
  }

  clickToSendQuestion(): void {
    if (!this.isWaitingForAnswer && this.chatMessages.length && !this.isChatConversationLimitReached) {
      if (this.chatMessages[0].content.content === this.documentData.prompt_first && this.chatMessages.length === 2) {
        this.bodyParams.additional_info = this.chatInput;
      } else {
        this.bodyParams.prompt = undefined;
        this.bodyParams.additional_info = undefined;
        this.bodyParams.user_ask = this.chatInput;
      }
      this.chatInput = '';
      this.chatMessages.push({
        content: {
          content: this.bodyParams.additional_info ? this.bodyParams.additional_info : this.bodyParams.user_ask,
          role: 'user'
        },
        document_id: this.documentData.id,
        file_path: null,
        file_name: this.bodyParams.file?.name,
        created_at: new Date(),
        filePreviewUrl: this.bodyParams.file ? this.previewFileURL : undefined
      });
      setTimeout(() => this.scrollToBottomOfChatConversation(), 0);
      this.sendQuestionToOpenAi();
    }
  }

  previewFile(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.previewFileURL = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  scrollToBottomOfChatConversation(): void {
    this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
  }
}
