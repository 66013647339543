import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WxClientPortfolio} from '../../interfaces/portfolio-interfaces';
import {EmailRegex, PasswordRegex} from '../../constants/general-variables';
import {AuthenticationHelperService} from '../../services/helpers/authentication-helper.service';
import {DEFAULT_AUTHENTICATED_PAGE, PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {LoginApiData} from '../../services/http-services/auth/authentication-http-interfaces';
import {AuthenticationHttpService} from '../../services/http-services/auth/authentication-http.service';

import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { OAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyARihZTnIPh3A3h_qyPbdnodWj306pMqEk',
  authDomain: 'wealx-platform.firebaseapp.com',
  projectId: 'wealx-platform',
  storageBucket: 'wealx-platform.appspot.com',
  messagingSenderId: '4975702468',
  appId: '1:4975702468:web:18afdd656a641221907491',
  measurementId: 'G-H7X7F1XQ4T'
};

const app = initializeApp(firebaseConfig);

export enum loginPageEnums {
  errMsg = 'Wrong credentials, please try again.'
}
@Component({
  selector: 'wx-login',
  templateUrl: './login.component.html',
  styles: ``
})
export class LoginComponent {
  loginForm!: FormGroup;
  formTriedToSubmit = false;
  isPasswordShow = false;
  isLoaderActive = false;
  errMessage = '';
  @Output() cancelModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<WxClientPortfolio> = new EventEmitter<WxClientPortfolio>();
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private formBuilder: FormBuilder,
              private authHelper: AuthenticationHelperService,
              private authenticationHttpService: AuthenticationHttpService ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      password: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
    });
  }

  submitLoginForm(): void {
    this.formTriedToSubmit = true;
    if (this.loginForm.valid) {
      this.isLoaderActive = true;
      this.authenticationHttpService.login(this.loginForm.value).then((res: LoginApiData) => {
        this.authHelper.clientLogin(res.user, res.token, DEFAULT_AUTHENTICATED_PAGE.page.pagePath);
        this.isLoaderActive = false;
      }, error => {
        this.isLoaderActive = false;
        this.errMessage = loginPageEnums.errMsg;
      });
    }
  }

  callGoogleLogin(): void {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;

        this.isLoaderActive = true;
        // @ts-ignore
        this.authenticationHttpService.firebase(user?.accessToken).then((res: LoginApiData) => {
          this.authHelper.clientLogin(res.user, res.token, DEFAULT_AUTHENTICATED_PAGE.page.pagePath);
          this.isLoaderActive = false;
        }, error => {
          this.isLoaderActive = false;
          this.errMessage = loginPageEnums.errMsg;
        });
      }).catch((error) => {
        this.errMessage = error.message;
    });
  }

  callAppleLogin(): void {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;

        this.isLoaderActive = true;
        // @ts-ignore
        this.authenticationHttpService.firebase(user?.accessToken).then((res: LoginApiData) => {
          this.authHelper.clientLogin(res.user, res.token, DEFAULT_AUTHENTICATED_PAGE.page.pagePath);
          this.isLoaderActive = false;
        }, error => {
          this.isLoaderActive = false;
          this.errMessage = loginPageEnums.errMsg;
        });
      }).catch((error) => {
        this.errMessage = error.message;
    });
  }
}
