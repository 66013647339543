<div class="wrapper-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="welcome-text">
      <span>{{ sayHello() }}, {{authHelperService.getUserData.first_name}} {{authHelperService.getUserData.last_name}}</span>
    </div>
    <!--   Portfolios   -->
    <div>
      <span class="title-dashboard">Consolidate your investment portfolios</span>
      <div class="zc-d-flex zc-justify-between">
        <wx-search
          [searchPlaceholder]="'Search by portfolio name...'"
          (searchQueryChange)="onPortfolioSearchQueryChange($event)">
        </wx-search>
      </div>
      <div class="wrapper-portfolio-cards">
        <div class="grid mobile-grid">
          <div *ngFor="let portfolio of filteredPortfolios" class="grid__m-6 grid__l-4 grid__xxl-3">
            <div (click)="navigateToPortfolio(portfolio)" class="wrapper-portfolio-card" [ngClass]="['pending', 'completed'].includes(portfolio.status) ? portfolio.status : PortfolioStatus.processing">
              <div class="icon-trash" (click)="$event.stopPropagation(); portfolioForDelete = portfolio">
                <i class="fa-solid fa-trash"></i>
              </div>
              <div class="wrapper-info-card-portfolio">
                <div>
              <span class="label-portfolio-name">{{portfolio.name}}</span>
                </div>
                <span class="label-client-name">{{portfolio.client_name}}</span>
              </div>
              <div class="zc-d-flex zc-align-items zc-justify-between">
                <div class="zc-d-flex zc-align-items">
                  <i class="fa-solid fa-check completed-icon"></i>
                  <i class="fa-solid fa-spinner processing-icon"></i>
                  <i class="fa-solid fa-clock pending-icon"></i>
                  <i class="fa-solid fa-circle-exclamation fail-icon"></i>
                  <div class="wrapper-status" [ngClass]="portfolio.status">
                    <span>{{portfolio.status === PortfolioStatus.pending ? 'Waiting for documents' : portfolio.status}}</span>
                  </div>
                </div>
                <span class="label-date">{{portfolio.createdAt | date:'dd.MM.yyyy'}}</span>
              </div>
              <div *ngIf="portfolio.status === PortfolioStatus.processing" class="wrapper-progress-bar">
                <div class="progress-bar">
                  <div class="overlay-progress-bar" [style]="{'width': portfolio.progressBar + '%'}"></div>
                </div>
              </div>
              <div class="img-press-btn">
                <img src="../../../assets/icons/press-button.png" alt="">
              </div>
            </div>
          </div>
          <div class="grid__m-6 grid__l-4 grid__xxl-3">
            <div (click)="createNewPortfolioModalOpen = true"  class="wrapper-portfolio-card wrapper-portfolio-cards--add">
              <span>Create new portfolio</span>
              <i class="fa-solid fa-plus"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--   Documents   -->
    <div>
      <span class="title-dashboard">Check the accuracy of your bank statements</span>
      <div class="zc-d-flex zc-justify-between">
        <wx-search
          [searchPlaceholder]="'Search by Bank Checkup name...'"
          (searchQueryChange)="onDocumentsSearchQueryChange($event)">
        </wx-search>
        <div class="tabs-document-preview">
          <button *ngIf="!isDocumentsShowAsCards" (click)="createNewDocumentModalOpen = true" class="zc-btn zc-btn--primary btn-create-document"><span class="text-btn">Check new statement</span> <span class="icon-btn">+</span></button>
          <div (click)="isDocumentsShowAsCards = true" class="wrapper-tab-preview" [ngClass]="{'active': isDocumentsShowAsCards}">
            <i class="fa-solid fa-table"></i>
          </div>
          <div (click)="isDocumentsShowAsCards = false" class="wrapper-tab-preview" [ngClass]="{'active': !isDocumentsShowAsCards}">
            <i class="fa-solid fa-list"></i>
          </div>
        </div>
      </div>
      <div *ngIf="isDocumentsShowAsCards" class="wrapper-portfolio-cards">
        <div class="grid mobile-grid">
          <div *ngFor="let document of filteredDocuments" class="grid__m-6 grid__l-4 grid__xxl-3">
            <div (click)="navigateToDocuments(document)" class="wrapper-portfolio-card" [ngClass]="document.status">
              <div class="wrapper-info-card-portfolio">
                <div>
                  <span class="label-portfolio-name">{{document.name}}</span>
                </div>
                <span class="label-client-name">{{document.statement_name}}</span>
              </div>
              <div class="icon-trash" (click)="$event.stopPropagation(); documentForDelete = document">
                <i class="fa-solid fa-trash"></i>
              </div>
              <div class="zc-d-flex zc-align-items zc-justify-between">
                <div class="zc-d-flex zc-align-items">
                  <i class="fa-solid fa-check completed-icon"></i>
                  <i class="fa-solid fa-spinner processing-icon"></i>
                  <i class="fa-solid fa-clock pending-icon"></i>
                  <div class="wrapper-status" [ngClass]="document.status">
                    <span>{{document.status}}</span>
                  </div>
                </div>
                <span class="label-date">{{document.created_at | date:'dd.MM.yyyy'}}</span>
              </div>
            </div>
          </div>
          <div class="grid__m-6 grid__l-4 grid__xxl-3">
            <div (click)="createNewDocumentModalOpen = true" class="wrapper-portfolio-card wrapper-portfolio-cards--add">
              <span>New Bank Checkup</span>
              <i class="fa-solid fa-plus"></i>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isDocumentsShowAsCards && initialDocuments.length && isRequestFinished" class="wrapper-table-documents">
        <div class="zc-table">
          <div class="zc-header-table">
            <div class="zc-header-row">
              <div class="zc-header-column">
                <span>Bank Checkup name</span>
              </div>
              <div class="zc-header-column column-2">
                <span>Document name</span>
              </div>
              <div class="zc-header-column column-3">
                <span>Status</span>
              </div>
              <div class="zc-header-column column-4">
                <span>Date created</span>
              </div>
              <div class="zc-header-column column-5">
                <span>Action</span>
              </div>
            </div>
          </div>
          <div class="zc-content-table">
            <div *ngFor="let document of filteredDocuments" (click)="navigateToDocuments(document)"  class="zc-content-row">
              <div class="zc-content-column">
                <span>{{document.name}}</span>
              </div>
              <div class="zc-content-column column-2">
                <span>{{document.statement_name}}</span>
              </div>
              <div class="zc-content-column column-3">
                <div class="wrapper-status" [ngClass]="document.status">
                  <span>{{document.status}}</span>
                </div>
              </div>
              <div class="zc-content-column column-4">
                <span>{{document.created_at | date:'dd.MM.yyyy'}}</span>
              </div>
              <div class="zc-content-column column-5">
                <div class="buttons-history">
                  <div class="wrapper-button-history"(click)="$event.stopPropagation(); documentForDelete = document">
                    <img src="../../../../assets/icons/icon-trash.svg" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isDocumentsShowAsCards && !initialDocuments.length && isRequestFinished"
       class="wrapper-content-page wrapper-content-page--nothing-to-display">
    <div class="wrapper-nothing-to-display">
      <div class="nothing-to-display">
        <img src="../../../assets/images/illustration-nothing-to-display.svg" alt="">
        <span class="title">Nothing to display</span>
        <span class="description">To get started create your first Bank Checkup</span>
        <button class="zc-btn zc-btn--primary" (click)="createNewDocumentModalOpen = true">
          <span>Create new document</span></button>
      </div>
    </div>
  </div>
  <wx-create-portfolio-modal
    @fadeInOutAnimation
    *ngIf="createNewPortfolioModalOpen"
    (cancelModal)="createNewPortfolioModalOpen = false"
    (confirmModal)="onConfirmCreatePortfolioForm($event)">
  </wx-create-portfolio-modal>

  <wx-confirm-modal
    *ngIf="portfolioForDelete !== null"
    [portfolioName]="portfolioForDelete?.name"
    [type]="'portfoliio'"
    (cancelModal)="portfolioForDelete = null"
    (confirmModal)="onConfirmDeletePortfolio()"
  ></wx-confirm-modal>

  <wx-create-document-modal
    @fadeInOutAnimation
    *ngIf="createNewDocumentModalOpen"
    (cancelModal)="createNewDocumentModalOpen = false"
    (confirmModal)="onConfirmCreateDocumentForm($event)"
  ></wx-create-document-modal>

  <wx-confirm-modal
    *ngIf="documentForDelete !== null"
    [portfolioName]="documentForDelete?.name"
    [type]="'document'"
    (cancelModal)="documentForDelete = null"
    (confirmModal)="onConfirmDeleteDocument()"
  ></wx-confirm-modal>

  <wx-payment-modal
    *ngIf="isPaymentModalOpen"
    (confirmPayment)="onConfirmPaymentModal()"
  ></wx-payment-modal>

  <wx-successfully-subscribed-modal
    *ngIf="isSuccessfullySubscribedModalOpen"
    (confirmModal)="onConfirmSubscriptionSuccessfully()"
  ></wx-successfully-subscribed-modal>
</div>
