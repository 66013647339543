<div class="wrapper-content-company-info">
  <div class="grid mobile-grid">
    <div class="grid__l-8">
      <div class="zc-card-wrapper zc-position-relative">
        <wx-loader *ngIf="!companyProfile"></wx-loader>
        <div class="zc-header-card">
          <span>About</span>
        </div>
        <div class="zc-content-card">
          <div class="wrapper-header-card-about">
            <div class="header-card-about">
              <h3>CEO</h3>
              <span>{{companyProfile?.ceo}}</span>
            </div>
            <div class="header-card-about">
              <h3>Sector</h3>
              <span>{{companyProfile?.sector}}</span>
            </div>
            <div class="header-card-about">
              <h3>Industry</h3>
              <span>{{companyProfile?.industry}}</span>
            </div>
            <div class="header-card-about">
              <h3>Website</h3>
              <a class="zc-link-small" [href]="companyProfile?.website" target="_blank">{{companyProfile?.website}}</a>
            </div>
            <div class="header-card-about">
              <h3>Exchange</h3>
              <span>{{companyProfile?.exchangeShortName}}</span>
            </div>
          </div>
          <div class="wrapper-content-card-about">
            <h3>Description</h3>
            <div class="wrapper-description">
              <h4 [ngClass]="{'length-character-column length-character-column--5': !isCompanyDescriptionOpen }">{{companyProfile?.description}}</h4>
              <span class="read-more" (click)="isCompanyDescriptionOpen = !isCompanyDescriptionOpen">{{isCompanyDescriptionOpen ? 'Read less' : 'Read more'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid__l-4">
      <div class="zc-card-wrapper zc-position-relative">
        <wx-loader *ngIf="!companyProfile"></wx-loader>
        <div class="zc-header-card">
          <span>Your portfolio position</span>
        </div>
        <div class="zc-content-card">
          <h3>{{companyProfile?.companyName}} {{companyProfile?.symbol ? '[' + companyProfile?.symbol + ']' : ''}}</h3>
          <div class="zc-table-small">
            <div class="zc-table-header-small">

            </div>
            <div class="zc-table-content-small">
              <div class="zc-table-row-small">
                <span class="label-table-small">Quantity</span>
                <span class="value-table-small">{{portfolioData ? portfolioData?.quantity : 'N/A'}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">Market value</span>
                <span class="value-table-small">{{portfolioData ? (((portfolioData?.quantity | formatterNumberValuePipe) * (companyProfile?.price | formatterNumberValuePipe)) | number:'1.2-2') : 'N/A'}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">Gain/Loss</span>
                <span class="value-table-small">
                  {{portfolioData && ((portfolioData?.valuationInOriginalCurrency | returnPLPipe:{type: portfolioData.assetClassType, quantity: portfolioData.quantity, currentFmpPrice: (companyProfile?.price | formatterNumberValuePipe)}: 'number') !== 0)
                  ? ((portfolioData?.valuationInOriginalCurrency | returnPLPipe:{type: portfolioData.assetClassType, quantity: portfolioData.quantity, currentFmpPrice: (companyProfile?.price | formatterNumberValuePipe)}: 'number') | number:'1.2-2')
                  : 'N/A'}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid mobile-grid">
    <div class="grid__l-6">
      <div class="zc-card-wrapper zc-position-relative">
        <wx-loader *ngIf="!companyProfile"></wx-loader>
        <div class="zc-header-card">
          <span>Summary</span>
        </div>
        <div class="zc-content-card zc-content-card--summary">
          <div class="zc-table-small zc-table-small--right-radius">
            <div class="zc-table-header-small">

            </div>
            <div class="zc-table-content-small">
              <div class="zc-table-row-small">
                <span class="label-table-small">CIK</span>
                <span class="value-table-small">{{companyProfile?.cik}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">Industry</span>
                <span class="value-table-small">{{companyProfile?.industry}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">CUSIP</span>
                <span class="value-table-small">{{companyProfile?.cusip}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">Country</span>
                <span class="value-table-small">{{companyProfile?.country}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">Beta</span>
                <span class="value-table-small">{{companyProfile?.beta}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">Market Cap</span>
                <span class="value-table-small">{{companyProfile?.mktCap  | number:'1.2-2'}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">52-Week</span>
                <span class="value-table-small">{{companyProfile?.range}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">ROE</span>
                <span class="value-table-small">{{keyMetrics?.roe | number:'1.2-2'}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">P/E</span>
                <span class="value-table-small">{{keyMetrics?.peRatio | number:'1.2-2'}}</span>
              </div>
            </div>
          </div>
          <div class="zc-table-small zc-table-small--left-radius">
            <div class="zc-table-header-small">

            </div>
            <div class="zc-table-content-small">
              <div class="zc-table-row-small">
                <span class="label-table-small">Exchange</span>
                <span class="value-table-small">{{companyProfile?.exchangeShortName}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">Sector</span>
                <span class="value-table-small">{{companyProfile?.sector}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">ISIN</span>
                <span class="value-table-small">{{companyProfile?.isin}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">Price</span>
                <span class="value-table-small">{{companyProfile?.price | number:'1.2-2' }}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">Volume Avg.</span>
                <span class="value-table-small">{{companyProfile?.volAvg  | number:'1.2-2'}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">DCF</span>
                <span class="value-table-small">{{companyProfile?.dcf  | number:'1.2-2'}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">ROIC</span>
                <span class="value-table-small">{{keyMetrics?.roic | number:'1.2-2'}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">Debt/Equity</span>
                <span class="value-table-small">{{keyMetrics?.debtToEquity | number:'1.2-2'}}</span>
              </div>
              <div class="zc-table-row-small">
                <span class="label-table-small">P/B</span>
                <span class="value-table-small">{{keyMetrics?.pbRatio | number:'1.2-2'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid__l-6">
      <div class="zc-card-wrapper zc-position-relative">
        <wx-loader *ngIf="!isHistoricalChartReqFinished"></wx-loader>
        <div *ngIf="isHistoricalChartReqFinished" class="zc-header-card zc-border-none">
          <span>Historical Prices</span>
        </div>
        <wx-historical-prices-chart
          [ticker]="companyProfile?.symbol ? companyProfile.symbol : ''"
          (historicalChartReqFinish)="isHistoricalChartReqFinished = true">
        </wx-historical-prices-chart>
      </div>
    </div>
  </div>
  <div class="grid mobile-grid">
    <div class="grid__l-6">
      <div class="zc-card-wrapper zc-position-relative">
        <wx-loader *ngIf="!financialStatementFormattedData"></wx-loader>
        <div *ngIf="financialStatementFormattedData" class="zc-header-card zc-border-none">
          <span>Financial Statement</span>
          <div class="financial-statement-right">
            <div class="wrapper-item-statement-right">
              <img src="../../../../assets/icons/icon-net-income.svg" alt="">
              <span>Revenue</span>
            </div>
            <div class="vertical-line"></div>
            <div class="wrapper-item-statement-right">
              <img class="color-filter-green" src="../../../../assets/icons/icon-revenue.svg" alt="">
              <span>Net Income</span>
            </div>
          </div>
        </div>
        <wx-area-chart [chartType]="ChartTitles.financialStatement" [data]="financialStatementFormattedData"></wx-area-chart>
      </div>
    </div>
    <div class="grid__l-6">
      <div class="zc-card-wrapper zc-position-relative">
        <wx-loader *ngIf="!earningsData"></wx-loader>
        <div *ngIf="earningsData" class="zc-header-card zc-border-none">
          <span>Earnings</span>
          <div class="financial-statement-right">
            <div class="wrapper-item-statement-right">
              <div class="rectangle rectangle--purple"></div>
              <span>EPS Consensus</span>
            </div>
          </div>
        </div>
        <wx-bar-chart [data]="earningsData" [chartType]="ChartTitles.earnings"></wx-bar-chart>
      </div>
    </div>
  </div>
  <div class="grid mobile-grid">
    <div class="grid__l-6">
      <div class="zc-card-wrapper key-financials zc-position-relative">
        <wx-loader *ngIf="!ratios"></wx-loader>
        <div class="zc-header-card zc-border-none">
          <span>Key Financials</span>
        </div>
        <div class="zc-content-card">
          <div class="zc-table zc-table--history xs-mt-0 ">
            <div class="zc-content-table">
              <div *ngFor="let ratio of ratios | keyvalue" class="zc-content-row zc-content-row--key-financials">
                <div class="zc-content-column">
                  <span>{{ratio.key | returnFormattedRatioKey}}</span>
                </div>
                <div class="zc-content-column">
                  <span>{{ratio.value | toFixed}}</span>
                </div>
                <div class="zc-content-column">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid__l-6">
      <div class="zc-card-wrapper zc-position-relative">
        <div class="zc-header-card">
          <span>People Also Watch</span>
        </div>
        <div class="zc-content-card">
          <div class="wrapper-table-people-also-watch">
            <wx-loader *ngIf="!filteredPeopleAlsoWatch"></wx-loader>
            <div class="header-table-people-also-watch">
              <div class="row-people-also-watch">
                <div class="column-people-also-watch">
                  <span>Ticker</span>
                </div>
                <div class="column-people-also-watch">
                  <span>Company name</span>
                </div>
                <div class="column-people-also-watch">
                  <span>Changes</span>
                </div>
              </div>
            </div>
            <div class="content-table-people-also-watch">
              <div *ngFor="let company of filteredPeopleAlsoWatch | keyvalue" (click)="clickOnPeopleAlsoWatchItem(company.key)" class="row-people-also-watch">
                <div class="column-people-also-watch">
                  <span>{{company.key}}</span>
                </div>
                <div class="column-people-also-watch">
                  <span>{{company.value.companyProfile[0].companyName}}</span>
                </div>
                <div class="column-people-also-watch">
                  <span [ngClass]="{
                    'red': company.value.companyProfile[0].changes < 0,
                    'green': company.value.companyProfile[0].changes > 0,
                    'gray': company.value.companyProfile[0].changes === 0 }">
                    {{company.value.companyProfile[0].changes}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
