<div class="wrapper-login-page">
<!--  <div class="content-login-left">-->
<!--    <div class="overlay-login-image">-->
<!--      <span>Wealx</span>-->
<!--    </div>-->
<!--  </div>-->
  <div class="content-login-right">
    <div class="content-login">
      <div class="zc-d-flex zc-flex-column zc-align-items">
        <div class="wrapper-logo">
          <img src="../../../assets/images/logo-welxio.png" alt="">
        </div>
<!--        <span class="logo-text">Wealx</span>-->
        <span class="title">Forgot password</span>
        <span class="subtitle">Enter your email and change your password</span>
      </div>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="submitForgotPasswordForm()">
        <div class="wrapper-input">
          <span class="label-input">Email</span>
          <div class="zc-input">
            <input type="email"[placeholder]="'Email'" [formControl]="forgotPasswordForm.controls.email | formControl">
          </div>
          <span class="error-message">{{forgotPasswordForm.controls.email | formControlErrorMessages: 'Enter valid email.' : formTriedToSubmit}}</span>
        </div>
        <button class="zc-btn zc-btn--primary zc-position-relative" type="submit">
          <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
          <span>Send</span>
        </button>
        <div class="wrapper-link-sign-up">
          <span class="link-sign-up">Already have an account? <a class="zc-link-label" href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.login.pagePath]">Sign in</a></span>
        </div>

      </form>
    </div>
  </div>
</div>
