<div class="wrapper-chat">
  <div class="wrapper-chat-header">
<!--    <div class="wrapper-chat-header-left">-->
<!--      <i class="fa-regular fa-comments"></i>-->
<!--      <span>Conversation</span>-->
<!--    </div>-->
    <div class="wrapper-reset-chat" *ngIf="chatMessages.length && ((chatMessages[0].content.role === 'system' && chatMessages.length === 3) || (chatMessages[0].content.role !== 'system' && chatMessages.length === 2))" (click)="resetDocumentChat()">
      <i class="fa-solid fa-arrows-rotate"></i>
      <span>Reset Bank Checkup</span>
    </div>
    <a [href]="'https://portfolio-api.welxio.com/documents/test/messages/' + documentData?.id" target="_blank" class="wrapper-chat-header-right">
      <i class="fa-solid fa-download"></i>
      <span>Download Bank Checkup</span>
    </a>
  </div>
  <div class="wrapper-content-chat">
    <div #chatContainer class="wrapper-conversation"
         [class.chat-dragover]="isDragOver"
         (dragover)="onDragOver($event)"
         (dragenter)="onDragEnter($event)"
         (dragleave)="onDragLeave($event)"
         (drop)="onDrop($event)">
      <div class="wrapper-message-welcome" *ngIf="isShowInitialPrompts">
        <span class="welcome-text-1">Hello, {{authenticationHelperService.getUserData.first_name}}</span>
        <span class="welcome-text-2">How can I help you today?</span>
      </div>
      <div class="wrapper-prompt">
        <div *ngIf="isShowInitialPrompts" class="prompt-preview" (click)="onSelectPrompt(1)">
          <div class="text-prompt-wrapper">
            <span>{{documentData?.prompt_first}}</span>
          </div>
        </div>
        <div *ngIf="isShowInitialPrompts" class="prompt-preview" (click)="onSelectPrompt(1)">
          <div class="text-prompt-wrapper">
            <span>{{documentData?.prompt_second}}</span>
          </div>
        </div>
        <div *ngIf="isShowInitialPrompts" class="prompt-preview" (click)="onSelectPrompt(3)">
          <div class="text-prompt-wrapper">
            <span>{{documentData?.prompt_third}}</span>
          </div>
        </div>
      </div>
      <div *ngFor="let message of chatMessages"
           class="wrapper-message"
           [ngClass]="{
             'answer': message.content.role === ChatRole.assistant,
             'question': message.content.role === ChatRole.user,
             'file': message.file_name && (message.file_name | slice: -3) === 'pdf',
             'image': message.file_name && (message.file_name | slice: -3) !== 'pdf'
           }">
        <div *ngIf="message.content.role !== 'system'" class="message">
          <img *ngIf="message.file_name && (message.file_name | slice: -3) !== 'pdf'" [src]="message.filePreviewUrl" alt="">
          <div class="pdf-wrapper-preview"  *ngIf="message.file_name && (message.file_name | slice: -3) === 'pdf'">
            <img src="../../../assets/icons/icon-pdf.svg" (click)="documentsHttpService.downloadFile(message.file_path, message.file_name)" class="fa-solid fa-file-pdf"/>
            <div class="pdf-wrapper-text">
              <span class="pdf-name">{{message.file_name}}</span>
            </div>
          </div>
          <span *ngIf="message.content.role === ChatRole.user">{{message.content.content}}</span>
          <markdown *ngIf="message.content.role === ChatRole.assistant" [data]="message.content.content"></markdown>
          <div class="date-chat">{{message.created_at | date: 'dd. MM. yyyy. hh:mm a' }}</div>
        </div>
      </div>
      <div *ngIf="isWaitingForAnswer" class="wrapper-chat-typing">
        <div class="chat-bubble">
          <div class="typing">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
      </div>
      <div @fadeInOutAnimation *ngIf="bodyParams.file" class="preview-pdf-chat-conversation" [ngClass]="{'preview-image': (bodyParams.file?.type !== 'application/pdf')}">
        <div class="image-preview-upload">
          <img [src]="previewFileURL" alt="preview-uploaded-image">
          <span>{{bodyParams.file.name}}</span>
        </div>
        <div *ngIf="bodyParams.file?.type === 'application/pdf'" class="info-preview-pdf-file-chat">
          <img src="../../../../../../assets/icons/icon-pdf.svg" alt="">
          <span>{{bodyParams.file.name}}</span>
        </div>
        <i (click)="removeFile()" class="fa-regular fa-circle-xmark"></i>
      </div>
      <div class="wrapper-dragover">

      </div>
    </div>
    <div class="wrapper-send-message" [ngClass]="{'disabled-message': isChatConversationLimitReached || isWaitingForAnswer || !chatMessages.length}">
      <input type="text" [readonly]="isWaitingForAnswer" [(ngModel)]="chatInput" (keydown.enter)="clickToSendQuestion()" placeholder="Ask a Question">
      <label class="upload-file-chat">
        <i class="fa-solid fa-paperclip"></i>
        <input #fileInput [disabled]="isWaitingForAnswer" type="file" accept=".pdf, .jpeg, .jpg, .png" (change)="onFileChange($event)">
      </label>
      <button [disabled]="isWaitingForAnswer" (click)="clickToSendQuestion()" class="zc-btn zc-btn--chat" type="button">
        <i class="fa-regular fa-paper-plane"></i>
      </button>
      <div class="overlay-not-allowed">
        <span *ngIf="isChatConversationLimitReached">You reached maximum number of prompts.</span>
      </div>
    </div>
  </div>
</div>
