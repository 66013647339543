import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Countries, EmailRegex, PasswordRegex} from '../../constants/general-variables';
import {confirmMatchControlsValidator} from '../../validators/form-validators.validator';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationHttpService} from '../../services/http-services/auth/authentication-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationHelperService} from '../../services/helpers/authentication-helper.service';
import {PaymentHttpService} from "../../services/http-services/payment/payment-http.service";

@Component({
  selector: 'wx-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
  registerForm!: FormGroup;
  formTriedToSubmit = false;
  isPasswordShow = false;
  isConfirmPasswordShow = false;
  isLoaderActive = false;
  protected readonly PAGES_AND_PATHS= PAGES_AND_PATHS;

  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private authHelper: AuthenticationHelperService,
              private paymentHttpService: PaymentHttpService,
              private authenticationHttpService: AuthenticationHttpService) {
  }

  async ngOnInit(): Promise<void> {
    this.buildForm();
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.registerForm.controls.token.patchValue(params.token);
      this.registerForm.controls.email.patchValue(params.email);
    });
  }

  buildForm(): void {
    this.registerForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      password: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
      password_confirmation: ['', [Validators.required, Validators.pattern(PasswordRegex)]],
      company_name: [''],
      company_website: [''],
      token: [undefined]
    }, {
      validators: confirmMatchControlsValidator('password', 'password_confirmation')
    });
  }

  async submitRegisterForm(): Promise<void> {
    this.formTriedToSubmit = true;
    if (this.registerForm.valid) {
      this.isLoaderActive = true;
      this.authenticationHttpService.register(this.registerForm.value).then(async res => {
        this.authHelper.clientLogin(res.user, res.token, PAGES_AND_PATHS.portfolio.pagePath);
      }).catch(({error}: {error: { errors: { [key: string]: string } }}) => {
        this.isLoaderActive = false;
        Object.values(error.errors).forEach(error => this.toastrService.error(error));
      });
    }
  }
}
