<div class="wrapper-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="header-wrapper-content-page">
      <div class="header-content-page-left">
        <img [routerLink]="[PAGES_AND_PATHS.portfolioStatementsHistory.pagePath, singlePortfolioData.id]"
             [state]="{data: singlePortfolioData}"
             src="../../../../assets/icons/icon-back-arrow.svg" alt="">
        <span class="title-page">Portfolio Statements</span>
      </div>
      <div class="header-content-page-right">

      </div>
    </div>
    <div class="wrapper-header-statistics xs-pb-0"  [ngStyle]="{'height': 80 + 'vh'}">
      <iframe *ngIf="portfolioStatementPreview" [src]="portfolioStatementPreview" width="100%" height="100%"></iframe>
    </div>
  </div>
</div>
