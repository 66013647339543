import {Component, OnInit} from '@angular/core';
import {PaymentHttpService} from '../../services/http-services/payment/payment-http.service';
import {
  loadStripe,
  Stripe,
  StripeAddressElement, StripeCardCvcElement,
  StripeCardExpiryElement, StripeCardNumberElement,
  StripeElements
} from '@stripe/stripe-js';
import {environment} from '../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationHttpService} from "../../services/http-services/auth/authentication-http.service";

@Component({
  selector: 'wx-settings',
  templateUrl: './settings.component.html',
  providers: [DatePipe]
})
export class SettingsComponent implements OnInit {
  registerForm!: FormGroup;
  formTriedToSubmit = false;
  cardData: any;
  invoices: any[] = [];
  clientSecret = '';
  stripe: Stripe | null = null;
  elements!: StripeElements;
  addressElement!: StripeAddressElement;
  cardNumberElement!: StripeCardNumberElement;
  cardExpiryElement!: StripeCardExpiryElement;
  cardCvcElement!: StripeCardCvcElement;
  cardErrors = '';
  cardExpiryErrors = '';
  cardCvcErrors = '';
  isCancelSubscriptionModalOpen = false;
  isResumeSubscriptionModalOpen = false;
  isLoaderActive = false;
  initLoading = true;
  hide = false;
  subscriptionStatus: any;
  constructor(private paymentHttpService: PaymentHttpService,
              private datePipe: DatePipe,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private authenticationHttpService: AuthenticationHttpService) {
  }
  ngOnInit(): void {
    this.getSubscriptionStatus();
    this.getCard();
    this.getInvoices();
    this.getInvoices();
    this.buildForm();

    this.authenticationHttpService.me().then(async res => {
      this.registerForm.controls.first_name.patchValue(res.first_name);
      this.registerForm.controls.last_name.patchValue(res.last_name);
      this.registerForm.controls.company_name.patchValue(res.company_name);
    });
  }

  buildForm(): void {
    this.registerForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      company_name: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  getSubscriptionStatus(): void {
    this.paymentHttpService.getSubscriptionStatus().then(res => {
      this.subscriptionStatus = res;
    });
  }

  getCard(): void {
    this.paymentHttpService.getPaymentMethod().then(res => {
      this.initLoading = false;

      if (res.success) {
        this.cardData = res.data;
        this.initStripeForm();
      }
    }).catch(() => {
      this.initLoading = false;
      this.hide = true;
    });
  }

  getInvoices(): void {
    this.paymentHttpService.getInvoices().then(res => {
      if (res.success) {
        this.invoices = res.data;
      }
    });
  }

  async initStripeForm(): Promise<void> {
    this.stripe = await loadStripe(environment.STRIPE_KEY);
    if (this.stripe) {
      this.elements = this.stripe.elements();
      this.cardNumberElement = this.elements.create('cardNumber', {
        classes: {
          base: 'card-element'
        },
        style: {
          base: {
            color: '#32325d',
            lineHeight: '44px',
            fontFamily: '"Archivo Regular, Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#8d8d8d'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        },
        showIcon: true,
        placeholder: `**** **** **** ${this.cardData.card.last4}`,
      } );
      this.cardExpiryElement = this.elements.create('cardExpiry', {
        classes: {
          base: 'card-expiry'
        },
        style: {
          base: {
            color: '#32325d',
            lineHeight: '44px',
            fontFamily: '"Archivo Regular, Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#8d8d8d'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        },
        placeholder: `${this.cardData.card.exp_month.toString().padStart(2, '0')}/${this.cardData.card.exp_year.toString().slice(2, 4)}`,
      });
      this.cardCvcElement = this.elements.create('cardCvc', {
        classes: {
          base: 'card-cvc'
        },
        style: {
          base: {
            color: '#32325d',
            lineHeight: '44px',
            fontFamily: '"Archivo Regular, Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#8d8d8d'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        },
      });
      this.addressElement = this.elements.create('address', {
        mode: 'billing',
        defaultValues: {...this.cardData.billing_details},
      });
      this.addressElement.mount('#address-element');
      this.cardNumberElement.mount('#card-number-element');
      this.cardExpiryElement.mount('#card-expiry-element');
      this.cardCvcElement.mount('#card-cvc-element');

      this.cardNumberElement.on('change', ({error}: { error: any }) => {
        return this.cardErrors = error && error.message;
      });

      this.cardExpiryElement.on('change', ({error}: { error: any }) => {
        return this.cardExpiryErrors = error && error.message;
      });

      this.cardCvcElement.on('change', ({error}: { error: any }) => {
        return this.cardCvcErrors = error && error.message;
      });
    }
    this.getPaymentIntent();
  }

  getPaymentIntent(): void {
    this.paymentHttpService.paymentIntent().then((res: any) => {
      this.clientSecret = res.client_secret;
    });
  }

  handleUpdateForm(event: Event): void {
    event.preventDefault();

    if (this.registerForm.valid) {
      this.isLoaderActive = true;
      this.authenticationHttpService.update(this.registerForm.value).then(async res => {
        this.isLoaderActive = false;
      }).catch(({error}: { error: { errors: { [key: string]: string } } }) => {
        this.isLoaderActive = false;
        Object.values(error.errors).forEach(error => this.toastrService.error(error));
      });
    }
  }

  async handleStripeForm(event: Event): Promise<void> {
    event.preventDefault();

    const {complete, value} = await this.addressElement.getValue();
    if (complete && this.stripe) {
      this.isLoaderActive = true;
      const {setupIntent, error} = await this.stripe.confirmCardSetup(
        this.clientSecret, {
          payment_method: {
            card: this.cardNumberElement,
            billing_details: value
          }
        }
      );
      if (error) {
        console.log(error);
      } else {
        const reqObj = {
          ...value,
          paymentMethod: setupIntent.payment_method,
        };
        this.paymentHttpService.setPaymentMethod(reqObj).then((paymentResponse: any) => {
          this.isLoaderActive = false;
          this.toastrService.success('Successfully updated card.');
          this.authenticationHttpService.update(value).then();
        });
      }
    }
  }

  onConfirmCancelSubscription(): void {
    this.paymentHttpService.cancelSubscription().then(res => {
      if (res.success) {
        this.isCancelSubscriptionModalOpen = false;
        this.subscriptionStatus.canceled = true;
        this.toastrService.success('Successfully canceled subscription, your subscription end date: ' + this.datePipe.transform(res.data.ends_at, 'dd.MM.yyyy.'));
      }
    });
  }

  onConfirmResumeSubscription(): void {
    this.paymentHttpService.resumeSubscription().then(res => {
      if (res.success) {
        this.isResumeSubscriptionModalOpen = false;
        this.subscriptionStatus.canceled = false;
        this.toastrService.success('Successfully resumed subscription.');
      }
    });
  }
}
