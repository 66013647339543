<div class="wrapper-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div *ngIf="singlePortfolioData.status === PortfolioStatus.completed && !isPortfolioAssetsShow && portfolioData"
       class="wrapper-content-page">
    <div class="header-wrapper-content-page">
      <div class="header-content-page-left">
        <img [routerLink]="PAGES_AND_PATHS.portfolio.pagePath" src="../../../../assets/icons/icon-back-arrow.svg" alt=""
             height="32" width="32">
        <span *ngIf="!editPortfolioName" class="title-page">{{singlePortfolioData.name}} <i (click)="editPortfolioName = true" class="fa fa-pencil" aria-hidden="true"></i></span>
        <div *ngIf="editPortfolioName">
          <form [formGroup]="portfolioForm" (ngSubmit)="submitPortfolioForm()">
            <div class="wrapper-input">
              <div class="zc-input">
                <input type="email"[placeholder]="'Title'" [formControl]="portfolioForm.controls.title | formControl">
                <button style="margin-left: 5px; margin-top: 2px" class="zc-btn zc-btn--primary zc-position-relative" type="submit">
                  <span>Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="header-content-page-right">
      </div>
    </div>
    <div *ngIf="singlePortfolioData.status === PortfolioStatus.completed && !isPortfolioAssetsShow && portfolioData"
         class="wrapper-header-statistics">
      <div class="header-content-page-left">
        <span class="label-date xs-mr-10">Economic Data Calendar:</span>
        <div class="wrapper-date-info">
          <wx-date-picker
            [defaultDate]="initialDate | returnFormattedDatePickerPipe"
            (dateSelected)="onDateChange($event)">
          </wx-date-picker>
        </div>
      </div>
      <div class="header-content-page-right">
        <button class="zc-btn zc-btn--gray" (click)="downloadExcel()">
          <img src="../../../../assets/icons/icon-quotes.svg" alt="">
          <span>Download Excel</span>
        </button>
        <button class="zc-btn zc-btn--gray" (click)="isUploadModalShow = true">
          <img src="../../../../assets/icons/icon-upload-statements.svg" alt="">
          <span>Upload Statements</span>
        </button>
        <button class="zc-btn zc-btn--primary"
                [routerLink]="[PAGES_AND_PATHS.portfolioStatementsHistory.pagePath, singlePortfolioData.id]"
                [state]="{data: singlePortfolioData}">
          <span>Documents</span></button>
      </div>
    </div>
    <div *ngIf="singlePortfolioData.status === PortfolioStatus.completed && !isPortfolioAssetsShow && portfolioData"
         class="wrapper-content-satistics">
      <div class="grid mobile-grid">
        <div class="grid__m-6">
          <div class="zc-card-wrapper zc-card-wrapper--small zc-position-relative">
            <wx-loader *ngIf="!isAssetsPriceRequestFinished"></wx-loader>
            <div class="header-card">
              <div class="icon-wrapper">
                <img src="../../../../assets/icons/icon-portfolio-valuation.svg" alt="">
              </div>
              <span>Portfolio Valuation</span>
            </div>
            <div class="content-card">
              <span
                class="info-value-card"> {{totalPortfoliosValuation | number:'1.2-2'}}</span>
              <div class="wrapper-label-info">
                <div class="label-info-dropdown">
                  <span class="label-info">Base Currency:</span>
                  <div class="dropdown-currency" [ngClass]="{'open': isBaseCurrencyDropdownOpen}">
                    <div #dropdownCurrencyBtn class="select-currency" (click)="isBaseCurrencyDropdownOpen = !isBaseCurrencyDropdownOpen">
                      <span>{{selectedBaseCurrency}}</span>
                      <i class="fa-solid fa-sort-down"></i>
                    </div>
                    <div class="content-dropdown-currency">
                      <span *ngFor="let baseCurrency of BaseCurrencyItems"
                        (click)="onCurrencyChange(selectedDate, baseCurrency.name)"
                        class="currency-item"
                        [ngClass]="{'active': baseCurrency.name === selectedBaseCurrency}">
                        {{baseCurrency.name}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid__m-6">
          <div class="zc-card-wrapper zc-card-wrapper--small">
            <div class="header-card">
              <div class="icon-wrapper">
                <img src="../../../../assets/icons/icon-increase-money.svg" alt="">
              </div>
              <span>Portfolio Market Screener</span>
            </div>
            <div class="content-card">
              <div class="wrapper-custodian-info zc-position-relative">
                <div class="custodian-info" *ngFor="let n of news" style="font-family: 'Roboto Regular'; font-size: 0.875rem">
                  <a class="zc-btn zc-btn--primary zc-cursor-pointer" (click)="openSingleAssets(n.symbol, filteredAssets[0])"><span>{{ n.symbol }}</span></a>
                  <a style="text-decoration: none" target="_blank" href="{{ n.url }}"> <span class="zc-news-title">  {{ n.title }}</span></a><br>
                  <span class="zc-news-description">{{ n.text?.substring(0, 120) }}...</span>
                  <br><br>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid__m-12">
          <div class="zc-card-wrapper zc-card-wrapper--small">
            <div class="header-card">
              <div class="icon-wrapper">
                <img src="../../../../assets/icons/icon-portfolio-valuation.svg" alt="">
              </div>
              <span>Consolidated Portfolios</span>
            </div>
            <div class="content-card">
              <div class="wrapper-custodian-info zc-position-relative">
                <div class="custodian-item" *ngFor="let monthlyPerformance of portfolioData.monthlyPerformance | keyvalue">
                  <div>
                    <div class="custodian-info">
                      <span class="custodian-info-label">Name:</span>
                      <span class="custodian-info-value">{{ monthlyPerformance.value.custodian.name ?? '/'}}</span>
                    </div>
                    <div class="custodian-info">
                      <span class="custodian-info-label">Address:</span>
                      <span class="custodian-info-value">{{ monthlyPerformance.value.custodian.address ?? '/'}}</span>
                    </div>
                    <div class="custodian-info">
                      <span class="custodian-info-label">BIC SWIFT:</span>
                      <span class="custodian-info-value">{{ monthlyPerformance.value.custodian.bicSwift ?? '/'}}</span>
                    </div>
                    <div class="custodian-info">
                      <span class="custodian-info-label">IBAN:</span>
                      <span class="custodian-info-value">{{ monthlyPerformance.value.custodian.iban ?? '/'}}</span>
                    </div>
                  </div>
                  <div class="custodian-total ">
                    <wx-loader *ngIf="!isAssetsPriceRequestFinished"></wx-loader>
                    <span class="total-label">{{monthlyPerformance.value.totalPortfolio.totalPortfolioValuation | number: '1.2-2'}}</span>
                    <span class="currency-label">Currency: {{selectedBaseCurrency}}</span>
                    <span class="currency-label">Statement Date: {{monthlyPerformance.value.statementDate | date: 'dd.MM.yyyy.'}}</span>
                  </div>
                </div>
              </div>
              <p *ngIf="showDateWarning"><b>Note: The bank statements you uploaded have different date ranges, which may result in inaccurate output.</b></p>
            </div>
          </div>
        </div>
        <div class="grid__m-6 grid__xxl-4">
          <wx-dropdown-multi-select
            [items]="filterItems"
            (itemSelected)="onFilterSelected($event)"
          ></wx-dropdown-multi-select>
        </div>
        <div class="grid__m-12">
          <div class="zc-table zc-table--companies zc-position-relative">
            <wx-loader *ngIf="!isAssetsPriceRequestFinished"></wx-loader>
            <div class="zc-header-table">
              <div class="zc-header-row">
                <div class="zc-header-column">
                  <span>Security</span>
                </div>
                <div class="zc-header-column">
                  <span>Instrument Type</span>
                </div>
                <div class="zc-header-column">
                  <span>Market value</span>
                </div>
              </div>
            </div>
            <div class="zc-content-table">
              <div *ngFor="let portfolioAsset of filteredAssets" class="zc-content-row more-pdf"
                   (click)="handleOpenSingleAssets(portfolioAsset.assetClassType, portfolioAsset.ticker, portfolioAsset)"
                   [ngClass]="{'zc-cursor-pointer': (portfolioAsset.ticker && (portfolioAsset.assetClassType.toLowerCase() === 'equities' || portfolioAsset.assetClassType.toLowerCase() === 'etf' || portfolioAsset.assetClassType.toLowerCase() === 'equity'))}">
                <div class="wrapper-flex-column-more-pdf">
                  <div class="zc-content-column">
                    <div class="wrapper-collapse-arrow"
                         (click)="$event.stopPropagation(); portfolioAsset.isShowPdf = !portfolioAsset.isShowPdf">
                      <img [ngClass]="{'rotate-icon': portfolioAsset.isShowPdf}"
                           src="../../../assets/icons/icon-arrow-left-paggination.svg" alt="">
                    </div>
                    <span>{{portfolioAsset.name}}</span>
                  </div>
                  <div class="zc-content-column">
                    <span>{{portfolioAsset.assetClassType | capitalizeStringPipe}}</span>
                  </div>
                  <div class="zc-content-column" *ngIf="portfolioAsset.assetClassType.toLowerCase() === 'cash'">
                    <span>{{portfolioAsset.valuationInOriginalCurrency ? (portfolioAsset.valuationInOriginalCurrency | formatterNumberValuePipe | number:'1.2-2') : 0}} {{portfolioAsset?.positionCurrency}}</span>
                  </div>
                  <div class="zc-content-column" *ngIf="portfolioAsset.assetClassType.toLowerCase() !== 'cash'">
                    <span>{{portfolioAsset.valuationInOriginalCurrency ? (portfolioAsset.valuationInOriginalCurrency | formatterNumberValuePipe | number:'1.2-2') : 0}} {{selectedBaseCurrency}}</span>
                  </div>
                </div>
                <div class="more-pdf-table" *ngIf="portfolioAsset.isShowPdf">
                  <div class="zc-header-table">
                    <div class="zc-header-row">
                      <div class="zc-header-column">
                        <span>Instrument Type</span>
                      </div>
                      <div class="zc-header-column">
                        <span>Custodian</span>
                      </div>
                      <div class="zc-header-column">
                        <span>Statement date</span>
                      </div>
                      <div class="zc-header-column">
                        <span>Market value</span>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let pdfAsset of portfolioAsset.pdf" class="zc-content-row"
                       (click)="handleOpenSingleAssets(pdfAsset.assetClassType, pdfAsset.ticker, pdfAsset)"
                       [ngClass]="{'zc-cursor-pointer': (pdfAsset.ticker && (pdfAsset.assetClassType.toLowerCase() === 'equities' || pdfAsset.assetClassType.toLowerCase() === 'etf' || pdfAsset.assetClassType.toLowerCase() === 'equity'))}">
                    <div class="zc-content-column">
                      <span>{{pdfAsset.assetClassType | capitalizeStringPipe}}</span>
                    </div>
                    <div class="zc-content-column">
                      <span>{{portfolioData?.files[pdfAsset.pdfName].statementDate.custodianInformation.name | capitalizeStringPipe}}</span>
                    </div>
                    <div class="zc-content-column">
                      <span>{{portfolioData?.files[pdfAsset.pdfName].statementDate.statementDate | date: 'dd.MM.yyyy.'}}</span>
                    </div>
                    <div class="zc-content-column" *ngIf="pdfAsset.assetClassType.toLowerCase() === 'cash'">
                      <span>{{pdfAsset.valuationInOriginalCurrency ? (pdfAsset.valuationInOriginalCurrency | formatterNumberValuePipe | number:'1.2-2') : 0}} {{portfolioAsset?.positionCurrency}}</span>
                    </div>
                    <div class="zc-content-column" *ngIf="pdfAsset.assetClassType.toLowerCase() !== 'cash'">
                      <span>{{pdfAsset.valuationInOriginalCurrency ? (pdfAsset.valuationInOriginalCurrency | formatterNumberValuePipe | number:'1.2-2') : 0}} {{selectedBaseCurrency}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid__m-12" *ngIf="formattedMonthlyPerformanceData">
          <div class="zc-card-wrapper zc-card-wrapper--300">
            <div class="header-card monthly-performanse">
              <span>Monthly performance (%)</span>
            </div>
            <div class="content-card">
              <wx-area-chart
                [chartType]="ChartTitles.monthlyPerformance"
                [data]="formattedMonthlyPerformanceData">
              </wx-area-chart>
            </div>
          </div>
        </div>
        <div class="grid__m-4" *ngIf="formattedCurrencyExposureData">
          <div class="zc-card-wrapper zc-card-wrapper--300 zc-position-relative">
            <wx-loader *ngIf="!isAssetsPriceRequestFinished"></wx-loader>
            <wx-pie-chart
              [chartTitle]="'Currency Exposure'"
              [data]="formattedCurrencyExposureData">
            </wx-pie-chart>
          </div>
        </div>
        <div class="grid__m-4" *ngIf="formattedAssetAllocationData">
          <div class="zc-card-wrapper zc-card-wrapper--300">
            <wx-pie-chart
              [chartTitle]="'Asset Allocation'"
              [data]="formattedAssetAllocationData">
            </wx-pie-chart>
          </div>
        </div>
        <div class="grid__m-4" *ngIf="formattedAllocationBySectorData">
          <div class="zc-card-wrapper zc-card-wrapper--300">
            <wx-pie-chart
              [chartTitle]="'Allocation By Sector'"
              [data]="formattedAllocationBySectorData">
            </wx-pie-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="singlePortfolioData.status === PortfolioStatus.pending"
       class="wrapper-content-page wrapper-content-page--nothing-to-display">
    <div class="header-wrapper-content-page">
      <div class="header-content-page-left">
        <img [routerLink]="PAGES_AND_PATHS.portfolio.pagePath" src="../../../../assets/icons/icon-back-arrow.svg"
             alt="">
        <span class="title-page">{{singlePortfolioData.name}}</span>
      </div>
    </div>
    <div class="wrapper-nothing-to-display">
      <div class="nothing-to-display">
        <img src="../../../assets/images/illustration-nothing-to-display.svg" alt="">
        <span class="title">Ensure a better experience</span>
        <span class="description">Upload PDF bank statements with matching date ranges for more accurate results.</span>
        <button (click)="isUploadModalShow = true" class="zc-btn zc-btn--primary"><span>Upload statements</span>
        </button>
      </div>
    </div>
  </div>
  <wx-upload-statements
    @fadeInOutAnimation
    *ngIf="isUploadModalShow"
    [portfolioId]="portfolioId"
    (closeModal)="isUploadModalShow = false"
    (closeModalAndRedirect)="closeUploadStatementModalAndRedirect()">
  </wx-upload-statements>
  <gr-portfolio-assets *ngIf="singlePortfolioData.status === PortfolioStatus.completed && isPortfolioAssetsShow"
                       [assetsData]="fmpCompanyData ? fmpCompanyData[activeTicker.toLowerCase()] : null"
                       [activeAssetPortfolioData]="activeCompanyPortfolioData"
                       (clickOnBackArrow)="isPortfolioAssetsShow = false">
  </gr-portfolio-assets>
</div>
