import {Component, OnInit} from '@angular/core';
import {PortfolioHttpService} from "../../../services/http-services/portfolio/portfolio-http.service";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {WxClientPortfolio} from "../../../interfaces/portfolio-interfaces";

@Component({
  selector: 'wx-portfolio-statements-pdf-preview',
  templateUrl: './portfolio-statements-pdf-preview.component.html',
})

export class PortfolioStatementsPdfPreviewComponent implements OnInit {
  urlParams = {
    portfolioId: null,
    fileId: null,
  }
  singlePortfolioData!: WxClientPortfolio;
  portfolioStatementPreview: SafeResourceUrl | null = null;

  constructor(private activatedRoute: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private portfolioHttpService: PortfolioHttpService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res: any) => {
      this.singlePortfolioData = {...history.state.data};
      this.urlParams.portfolioId = res.portfolioId;
      this.urlParams.fileId = res.fileId
      this.getStatementPreview();
    });
  }


  getStatementPreview(): void {
    this.portfolioHttpService.postPortfolioStatementPreview(this.urlParams).then(res => {
      const blob = new Blob([res], {type: 'application/pdf'});
      const url = URL.createObjectURL(blob);
      this.portfolioStatementPreview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

    protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
}
