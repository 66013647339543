import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';
import {
  ForgotPasswordApiData,
  UpdatePortfolioTitle
} from "../auth/authentication-http-interfaces";

const PORTFOLIO_API_ROUTES = {
  GET_USERS: 'portfolios/users',
  GET_PORTFOLIOS: 'portfolios',
  GET_LATEST_EXCHANGE_RATE: 'portfolios/get-latest-currency',
  GET_HISTORY_EXCHANGE_RATE: 'portfolios/get-history-currency',
  DOWNLOAD: 'portfolios/download-csv-or-excel',
  GET_PORTFOLIO_STATEMENT_DATA: 'portfolios/:id',
  UPDATE_PORTFOLIO_STATEMENT_DATA: 'portfolios/:id/update-title',
  GET_COMPANY_PROFILE: '',
  POST_PORTFOLIO: 'portfolios',
  DELETE_PORTFOLIO: 'portfolios/:id',
  POST_UPLOAD_STATEMENTS: 'portfolios/:id/upload',
  POST_FMP_DATA: 'portfolios/fmp-data',
  POST_HISTORICAL_PRICE_CHART_DATA: 'portfolios/fmp-data/historical-price',
  POST_NEWS: 'portfolios/fmp-data/news',
  POST_EARNINGS_CHART_DATA: 'portfolios/fmp-data/historical-calendar',
  POST_ALL_PDF_HISTORICAL_PRICES: 'portfolios/fmp-data/price-from-pdf',
  POST_STATEMENTS_HISTORY: 'statement-history',
  DELETE_SINGLE_PDF: 'statement-history/:id',
  GET_PROCESS_BAR: 'process/:id',
  POST_PORTFOLIO_STATEMENT_PREVIEW: 'portfolios/:portfolioId/preview/:fileId'
};

@Injectable({
  providedIn: 'root'
})
export class PortfolioHttpService {

  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) { }
// GET USERS
  private getUsersReq(): Observable<any> {
    return this.httpClient.get(PORTFOLIO_API_ROUTES.GET_PORTFOLIOS);
  }

  getUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getUsersReq().subscribe((res: any) => {
        resolve({
          data: res.data,
        });
      }, error => {
        reject(error);
      });
    });
  }
  // GET USERS END

  // GET PORTFOLIOS
  private getPortfoliosReq(): Observable<any> {
    return this.httpClient.get(PORTFOLIO_API_ROUTES.GET_PORTFOLIOS);
  }

  getPortfolios(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getPortfoliosReq().subscribe((res: any) => {
        resolve({
          data: res.data,
        });
      }, error => {
        reject(error);
      });
    });
  }
  // GET PORTFOLIOS END

  // GET PORTFOLIO STATEMENT DATA
  private getPortfolioStatementDataReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PORTFOLIO_API_ROUTES.GET_PORTFOLIO_STATEMENT_DATA, urlParams);
    return this.httpClient.get(apiUrl);
  }

  getPortfolioStatementData(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getPortfolioStatementDataReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res,
        });
      }, error => {
        reject(error);
      });
    });
  }
  // GET PORTFOLIO STATEMENT DATA END

  // POST CREATE PORTFOLIO
  private postCreatePortfolioReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PORTFOLIO_API_ROUTES.POST_PORTFOLIO, bodyParams);
  }

  postCreatePortfolio(bodyParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postCreatePortfolioReq(bodyParams).subscribe((res: any) => {
        resolve({
          data: res.data,
        });
      }, error => {
        reject(error);
      });
    });
  }
  // POST CREATE PORTFOLIO END

  // POST CREATE PORTFOLIO
  private deletePortfolioReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(PORTFOLIO_API_ROUTES.DELETE_PORTFOLIO, urlParams)
    return this.httpClient.delete(url);
  }

  deletePortfolio(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.deletePortfolioReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data,
        });
      }, error => {
        reject(error);
      });
    });
  }
  // POST CREATE PORTFOLIO END

  // POST UPLOAD PORTFOLIO STATEMENT
  postUploadStatementReq(urlParams: Record<string, any>, bodyParams: any): Observable<HttpEvent<any>> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PORTFOLIO_API_ROUTES.POST_UPLOAD_STATEMENTS, urlParams);
    return this.httpClient.post(apiUrl, bodyParams, {reportProgress: true, observe: 'events'});
  }
  // POST UPLOAD PORTFOLIO STATEMENT END

  private getCompanyProfileReq(): Observable<any> {
    return this.httpClient.get(PORTFOLIO_API_ROUTES.GET_COMPANY_PROFILE);
  }

  getCompanyProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getCompanyProfileReq().subscribe((res: any) => {
        resolve({
          data: res.data,
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getFmpDataReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PORTFOLIO_API_ROUTES.POST_FMP_DATA, bodyParams);
  }

  getFmpData(bodyParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getFmpDataReq(bodyParams).subscribe((res: any) => {
        resolve({
          data: res,
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getHistoricalPriceChartDataReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PORTFOLIO_API_ROUTES.POST_HISTORICAL_PRICE_CHART_DATA, bodyParams);
  }

  getHistoricalPriceChartData(bodyParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getHistoricalPriceChartDataReq(bodyParams).subscribe((res: any) => {
        resolve({
          data: res,
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getNewsReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PORTFOLIO_API_ROUTES.POST_NEWS, bodyParams);
  }

  getNews(bodyParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getNewsReq(bodyParams).subscribe((res: any) => {
        resolve({
          data: res,
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getEarningsChartDataReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PORTFOLIO_API_ROUTES.POST_EARNINGS_CHART_DATA, bodyParams);
  }

  getEarningsChartData(bodyParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getEarningsChartDataReq(bodyParams).subscribe((res: any) => {
        resolve({
          data: res,
        });
      }, error => {
        reject(error);
      });
    });
  }


  private getAllPdfHistoricalPriceReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PORTFOLIO_API_ROUTES.POST_ALL_PDF_HISTORICAL_PRICES, bodyParams);
  }

  getAllPdfHistoricalPrice(bodyParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAllPdfHistoricalPriceReq(bodyParams).subscribe((res: any) => {
        resolve({
          data: res,
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getLatestExchangeRatesReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(PORTFOLIO_API_ROUTES.GET_LATEST_EXCHANGE_RATE, urlParams)
    return this.httpClient.get(apiUrl);
  }

  getLatestExchangeRates(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getLatestExchangeRatesReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res,
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getHistoryExchangeRatesReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(PORTFOLIO_API_ROUTES.GET_HISTORY_EXCHANGE_RATE, urlParams)
    return this.httpClient.get(apiUrl);
  }

  getHistoryExchangeRates(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getHistoryExchangeRatesReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res,
        });
      }, error => {
        reject(error);
      });
    });
  }

  private downloadReq(body: any): Observable<any> {
    return this.httpClient.post(PORTFOLIO_API_ROUTES.DOWNLOAD, body, { responseType: 'blob' });
  }

  download(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.downloadReq(body).subscribe((res: any) => {
        resolve({
          data: res,
        });
      }, error => {
        reject(error);
      });
    });
  }

  private postStatementsHistoryReq(urlParams: any, bodyParams: any): Observable<any> {
    const url = this.generalHttpHelperService.addUrlParams(PORTFOLIO_API_ROUTES.POST_STATEMENTS_HISTORY, urlParams);
    return this.httpClient.post(url, bodyParams) as any;
  }

  postStatementsHistory(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postStatementsHistoryReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve({
            data: res.data.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private deletePortfolioStatementReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(PORTFOLIO_API_ROUTES.DELETE_SINGLE_PDF, urlParams);
    return this.httpClient.delete(url) as any;
  }

  deletePortfolioStatement(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deletePortfolioStatementReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getPortfolioProgressBarReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(PORTFOLIO_API_ROUTES.GET_PROCESS_BAR, urlParams);
    return this.httpClient.get(url) as any;
  }

  getPortfolioProgressBar(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getPortfolioProgressBarReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  // POST PORTFOLIO STATEMENT PREVIEW START
  private postPortfolioStatementPreviewReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(PORTFOLIO_API_ROUTES.POST_PORTFOLIO_STATEMENT_PREVIEW, urlParams);
    return this.httpClient.post(url, {}, { responseType: 'blob' });
  }

  postPortfolioStatementPreview(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postPortfolioStatementPreviewReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private updateTitleReq(urlParams: any, bodyParams: UpdatePortfolioTitle): Observable<ForgotPasswordApiData> {
    return this.httpClient.post(this.generalHttpHelperService.replaceUrlParamsWithValues(PORTFOLIO_API_ROUTES.UPDATE_PORTFOLIO_STATEMENT_DATA, urlParams), bodyParams) as any;
  }

  updateTitle(urlParams: any, bodyParams: UpdatePortfolioTitle): Promise<ForgotPasswordApiData> {
    return new Promise<ForgotPasswordApiData>((resolve, reject) => {
      this.updateTitleReq(urlParams, bodyParams).subscribe((res: ForgotPasswordApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  // POST PORTFOLIO STATEMENT PREVIEW END
}
