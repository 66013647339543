<div class="wrapper-modal">
  <form [formGroup]="inviteMemberForm" (ngSubmit)="submitInviteMemberForm()" class="wrapper-content-modal">
    <div class="header-modal">
      <span class="title-modal">Member Invitation</span>
    </div>
    <div class="content-modal">
      <div class="wrapper-input">
        <span class="label-input">Email</span>
        <div class="zc-input">
          <input type="text" [formControl]="inviteMemberForm.controls.email | formControl">
        </div>
        <span class="error-message">{{inviteMemberForm.controls.email | formControlErrorMessages: 'Enter valid email address' : formTriedToSubmit}}</span>
      </div>
      <span class="label-input">When you invite members, they are included as users under your subscription plan and billed at the same rate as your existing plan.</span>
    </div>
    <div class="footer-modal">
      <button type="button" class="zc-btn zc-btn--gray" (click)="cancelModal.emit()"><span>Cancel</span></button>
      <button type="submit" [disabled]="isLoaderActive" class="zc-btn zc-btn--primary zc-position-relative">
        <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
        <span>Confirm</span>
      </button>
    </div>
  </form>
</div>
