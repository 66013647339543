<div class="wrapper-login-page">
  <div class="content-login-right">
    <div class="content-login">
      <div class="zc-d-flex zc-flex-column zc-align-items">
        <div class="wrapper-logo">
          <img src="../../../assets/images/logo-welxio.png" alt="">
        </div>
        <span class="title">Keep your bank in check</span> <br>
      </div>

      <form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">
        <div class="wrapper-input">
          <div class="zc-input">
            <input type="email" [formControl]="loginForm.controls.email | formControl" [placeholder]="'Email'">
          </div>
          <span class="error-message">{{loginForm.controls.email | formControlErrorMessages: 'Enter valid email.' : formTriedToSubmit}}</span>
        </div>
        <div class="wrapper-input">
          <div class="zc-input">
            <input [type]="isPasswordShow ? 'text' : 'password'" [formControl]="loginForm.controls.password | formControl" [placeholder]="'Password'">
            <i *ngIf="!isPasswordShow" (click)="isPasswordShow = true" class="fa-solid fa-eye"></i>
            <i *ngIf="isPasswordShow" (click)="isPasswordShow = false" class="fa-solid fa-eye-slash"></i>
          </div>
          <span class="error-message">{{loginForm.controls.password | formControlErrorMessages: 'Minimum 8 characters, one capital letter, one number and one special character.' : formTriedToSubmit}}</span>
          <span class="error-message" *ngIf="errMessage">{{errMessage}}</span>
        </div>
        <button class="zc-btn zc-btn--primary zc-position-relative" type="submit">
          <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
          <span>Sign In</span>
        </button>
        <div class="wrapper-link-sign-up">
          <span class="link-sign-up"><b>--- Or ---</b></span>
        </div>
        <a class="zc-btn zc-btn--primary zc-position-relative" style="display: flex; background: red;" (click)="callGoogleLogin()">
          <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
          <span style="margin: auto"><i class="fa-brands fa-google"></i> Continue with Google</span>
        </a>
        <a class="zc-btn zc-btn--primary zc-position-relative" style="display: flex; background: black;" (click)="callAppleLogin()">
          <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
          <span style="margin: auto"><i class="fa-brands fa-apple"></i> Continue with Apple</span>
        </a>
        <div class="wrapper-link-sign-up">
          <span class="link-sign-up"><a class="zc-link-label login" href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.register.pagePath]">Sign up with email</a></span>
        </div>
        <a class="zc-link-label login" href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.forgotPassword.pagePath]">Forgot your password?</a>
      </form>
    </div>
  </div>
</div>
