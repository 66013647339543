<div class="wrapper-page">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="header-wrapper-content-page">
      <div class="header-content-page-left">
        <img [routerLink]="[PAGES_AND_PATHS.singlePortfolio.pagePath, singlePortfolioData.id]" [state]="{data: singlePortfolioData}"
             src="../../../../assets/icons/icon-back-arrow.svg" alt="">
        <span class="title-page">Portfolio Statements</span>
      </div>
      <div class="header-content-page-right">

      </div>
    </div>
    <div class="wrapper-header-statistics xs-pb-0">
      <div class="grid mobile-grid gird-gap-16 xs-mb-0 w-100">
        <div class="grid__s-6 grid__m-4 grid__l-3">
          <wx-date-picker
            [datepickerLabel]="'From'"
            (dateSelected)="onSelectStartDate($event)"
          ></wx-date-picker>
        </div>
        <div class="grid__s-4 grid__l-3">
          <wx-date-picker
            [datepickerLabel]="'To'"
            (dateSelected)="onSelectEndDate($event)"
          ></wx-date-picker>
        </div>
        <div class="grid__m-4 grid__l-3 fixed-align">
          <wx-search class="w-100-search" [searchPlaceholder]="'Search statements...'" (searchQueryChange)="onSearchChange($event)"></wx-search>
        </div>
        <div class="grid__l-3 fixed-align">
          <div class="header-content-page-right zc-justify-content-end">
            <button (click)="isUploadModalShow = true" class="zc-btn zc-btn--gray">
              <img src="../../../../assets/icons/icon-upload-statements.svg" alt="">
              <span>Upload Statements</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-content-statements-history">
      <div class="grid mobile-grid">
        <div class="grid__m-12">
          <div class="zc-table zc-table--history">
            <div class="zc-header-table">
              <div class="zc-header-row">
                <div class="zc-header-column">
                  <span>Statements</span>
                </div>
                <div class="zc-header-column">
                  <span>Client name</span>
                </div>
                <div class="zc-header-column">
                  <span>Total</span>
                </div>
                <div class="zc-header-column">
                  <span>Statement date</span>
                </div>
                <div class="zc-header-column">
                  <span>Action</span>
                </div>
              </div>
            </div>
            <div class="zc-content-table">
              <div *ngFor="let statement of historyStatements" class="zc-content-row">
                <div class="zc-content-column">
                  <span>{{statement.name}}</span>
                </div>
                <div class="zc-content-column">
                  <span>{{singlePortfolioData.client_name}}</span>
                </div>
                <div class="zc-content-column">
                  <span>{{statement.total_portfolio?.totalPortfolioValuation ? (statement.total_portfolio.totalPortfolioValuation | formatterNumberValuePipe | number:'1.2-2') + '$' : '/'}}</span>
                </div>
                <div class="zc-content-column">
                  <span>{{statement.statement_date?.statementDate | date:'dd.MM.yyyy'}}</span>
                </div>
                <div class="zc-content-column">
                  <div class="buttons-history">
                    <div class="wrapper-button-history" [routerLink]="'/portfolio/' + bodyParams.portfolio_id + '/pdf-preview/' + statement?.id" [state]="{data: singlePortfolioData}">
                      <i class="fa-solid fa-eye"></i>
                    </div>
                    <div class="wrapper-button-history" (click)="statementForDelete = statement">
                      <img src="../../../../assets/icons/icon-trash.svg" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <wx-confirm-modal
      *ngIf="statementForDelete !== null"
      [portfolioName]="statementForDelete?.name"
      [type]="'statement'"
      (cancelModal)="statementForDelete = null"
      (confirmModal)="onConfirmDeleteStatement()"
    ></wx-confirm-modal>
    <wx-upload-statements
      @fadeInOutAnimation
      *ngIf="isUploadModalShow"
      [portfolioId]="singlePortfolioData.id"
      (closeModal)="isUploadModalShow = false"
      (closeModalAndRedirect)="closeUploadStatementModalAndRedirect()">
    </wx-upload-statements>
    <wx-pagination
      *ngIf="historyStatements.length"
      [currentPage]="1"
      [pagId]="'js-statement-history-page'"
      [perPage]="10"
      [totalItems]="1"
      (pageChanged)="onPageChanged($event)"
    ></wx-pagination>
  </div>

</div>
