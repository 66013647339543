// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_ENDPOINT: 'https://portfolio-api.welxio.com/api/V1/',
  PAYMENT_INTENT_API_ENDPOINT: 'https://portfolio-api.welxio.com/api/',
  STRIPE_KEY: 'pk_live_51NZw0WKnGL8QHpzXGv33Je1kCtPK4roYwHKnQIxq8vreWPtEwIa7FyJcmLV8pNjNiS2DP6CzE08soCN1Rz0Oh1uW00I8QhufxT',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
