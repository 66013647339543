<div class="wrapper-login-page">
<!--  <div class="content-login-left">-->
<!--    <div class="overlay-login-image">-->
<!--      <span>Wealx</span>-->
<!--    </div>-->
<!--  </div>-->
  <div class="wrapper-header-auth">

  </div>
  <div class="content-login-right">
    <div class="content-login registration">
      <div class="zc-d-flex zc-flex-column zc-align-items">
        <div class="wrapper-logo">
          <img src="../../../assets/images/logo-welxio.png" alt="">
        </div>
<!--        <span class="logo-text">Wealx</span>-->
        <span class="title">Create an account</span>
        <span class="subtitle">We'll use this information to complete your profile</span>
      </div>
      <div>
        <form [formGroup]="registerForm" (ngSubmit)="submitRegisterForm()" class="register-form step1">
          <div class="grid grid-gap-0-16">
            <div class="grid__l-6">
              <div class="wrapper-input">
                <div class="zc-input">
                  <input type="text"[placeholder]="'First name'" [formControl]="registerForm.controls.first_name | formControl">
                </div>
                <span class="error-message">{{registerForm.controls.first_name | formControlErrorMessages: null : formTriedToSubmit}}</span>
              </div>
            </div>
            <div class="grid__l-6">
              <div class="wrapper-input">
                <div class="zc-input">
                  <input type="text"[placeholder]="'Last name'" [formControl]="registerForm.controls.last_name | formControl">
                </div>
                <span class="error-message">{{registerForm.controls.last_name | formControlErrorMessages: null : formTriedToSubmit}}</span>
              </div>
            </div>
            <div class="grid__l-12">
              <div class="wrapper-input">
                <div class="zc-input">
                  <input type="email"[placeholder]="'Email'" [readonly]="registerForm.controls.token.value" [formControl]="registerForm.controls.email | formControl">
                </div>
                <span class="error-message">{{registerForm.controls.email | formControlErrorMessages: 'Enter valid email.' : formTriedToSubmit}}</span>
              </div>
            </div>
            <div class="grid__l-6">
              <div class="wrapper-input">
                <div class="zc-input">
                  <input [type]="isPasswordShow ? 'text' : 'password'" [formControl]="registerForm.controls.password | formControl" [placeholder]="'Password'">
                  <i *ngIf="!isPasswordShow" (click)="isPasswordShow = true" class="fa-solid fa-eye"></i>
                  <i *ngIf="isPasswordShow" (click)="isPasswordShow = false" class="fa-solid fa-eye-slash"></i>
                </div>
                <span class="error-message">{{registerForm.controls['password'] | formControlErrorMessages: 'Minimum 8 characters, one capital letter, one number and one special character.' : formTriedToSubmit}}</span>
              </div>
            </div>
            <div class="grid__l-6">
              <div class="wrapper-input">
                <div class="zc-input">
                  <input [type]="isConfirmPasswordShow ? 'text' : 'password'" [formControl]="registerForm.controls.password_confirmation | formControl" [placeholder]="'Confirm password*'">
                  <i *ngIf="!isConfirmPasswordShow" (click)="isConfirmPasswordShow = true" class="fa-solid fa-eye"></i>
                  <i *ngIf="isConfirmPasswordShow" (click)="isConfirmPasswordShow = false" class="fa-solid fa-eye-slash"></i>
                </div>
                <span class="error-message">{{registerForm.controls['password_confirmation'] | formControlErrorMessages: 'Minimum 8 characters, one capital letter, one number and one special character.' : formTriedToSubmit}}</span>
              </div>
            </div>
            <div class="grid__l-6">
              <div class="wrapper-input">
                <div class="zc-input">
                  <input type="text"[placeholder]="'Company name'" [formControl]="registerForm.controls.company_name | formControl">
                </div>
                <span class="error-message">{{registerForm.controls.company_name | formControlErrorMessages: null : formTriedToSubmit}}</span>
              </div>
            </div>
            <div class="grid__l-6">
              <div class="wrapper-input">
                <div class="zc-input">
                  <input type="text"[placeholder]="'Company website - optional'" [formControl]="registerForm.controls.company_website | formControl">
                </div>
                <span class="error-message">{{registerForm.controls.company_website | formControlErrorMessages: null : formTriedToSubmit}}</span>
              </div>
            </div>
            <div class="grid__l-12">
              <span class="subtitle">By signing up, you agree to the <a class="zc-link-label login" href="https://wealx.com/terms">Terms of Service</a> and <a class="zc-link-label login" href="https://wealx.com/privacy">Privacy Policy</a>.</span> <br><br>
              <button type="submit" class="zc-btn zc-btn--primary w-100"><span>Register</span></button>
            </div>
          </div>






        </form>
        <div class="wrapper-link-register">
          <div class="wrapper-link-sign-up">
            <span class="link-sign-up">Already have an account? <a class="zc-link-label register" href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.login.pagePath]">Sign in</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

