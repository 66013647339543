<div class="wrapper-page settings">
  <div class="wrapper-header-page">
    <div class="overlay-illustration-header"></div>
  </div>
  <div class="wrapper-content-page">
    <div class="header-wrapper-content-page">
      <div class="header-content-page-left">
        <span class="title-page">Account Settings</span>
      </div>
      <div class="header-content-page-right">
      </div>
    </div>
    <div class="payment-wrapper-content">
      <div class="payment-content">
        <div class="grid mobile-grid gird-gap-16">
          <div class="grid__m-12">
            <wx-loader *ngIf="initLoading"></wx-loader>
            <form class="grid mobile-grid" [formGroup]="registerForm" (submit)="handleUpdateForm($event)">
              <div class="grid__l-12">
                <span class="active-card-title">Account information</span>
                <span class="desc-payment">View and update your account details, profile and more.</span>
                <div class="payment-wrapper">
                  <div class="card-stripe settings">
                    <div class="grid__l-6">
                      <div class="wrapper-input">
                        <div class="zc-input">
                          <input type="text"[placeholder]="'First name'" [formControl]="registerForm.controls.first_name | formControl">
                        </div>
                        <span class="error-message">{{registerForm.controls.first_name | formControlErrorMessages: null : formTriedToSubmit}}</span>
                      </div>
                    </div>
                    <div class="grid__l-6">
                      <div class="wrapper-input">
                        <div class="zc-input">
                          <input type="text"[placeholder]="'Last name'" [formControl]="registerForm.controls.last_name | formControl">
                        </div>
                        <span class="error-message">{{registerForm.controls.last_name | formControlErrorMessages: null : formTriedToSubmit}}</span>
                      </div>
                    </div>
                    <div class="grid__l-6">
                      <div class="wrapper-input">
                        <div class="zc-input">
                          <input type="text"[placeholder]="'Company name'" [formControl]="registerForm.controls.company_name | formControl">
                        </div>
                        <span class="error-message">{{registerForm.controls.company_name | formControlErrorMessages: null : formTriedToSubmit}}</span>
                      </div>
                    </div>
                    <div class="grid__l-12">
                      <button type="submit" class="zc-btn zc-btn--primary w-100  zc-position-relative">
                      <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
                        <span>Update</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <form class="grid mobile-grid" [formGroup]="registerForm" (submit)="handleStripeForm($event)">
              <div class="grid__l-12 payment-wrapper" *ngIf="!hide">
                <span class="active-card-title">Payment Method</span>
                <span class="desc-payment">Update current payment method with new Credit Card details if needed.</span>
                <div class="card-stripe settings">
                  <div id="address-element"></div>
                  <div id="card-number-element"></div>
                  <div id="card-expiry-element"></div>
                  <div id="card-cvc-element"></div>
                </div>
                <div class="wrapper-button-stripe">
                  <button class="zc-btn zc-btn--primary zc-position-relative" type="submit">
                    <wx-loader *ngIf="isLoaderActive" [isButtonLoader]="true"></wx-loader>
                    <span>Update</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="grid mobile-grid gird-gap-16" *ngIf="cardData !== undefined">
          <div class="grid__m-12">
            <span class="active-card-title">Your Receipts</span>
            <span class="desc-payment">View and download all receipts</span>
          </div>
          <div class="grid__l-6  wrapper-invoices" *ngFor="let invoice of invoices">
            <div class="wrapper-info-invoice">
          <span class="label-info-invoice">
            Amount:
          </span>
              <span class="value-info-invoice">
            {{invoice.amount}}
                <span class="text-uppercase">{{invoice.currency}}</span>
          </span>
            </div>
            <div class="wrapper-info-invoice">
          <span class="label-info-invoice">
            Description:
          </span>
              <span class="value-info-invoice">
            {{invoice.description}}
          </span>
            </div>
            <div class="wrapper-info-invoice">
          <span class="label-info-invoice">
            Date:
          </span>
              <span class="value-info-invoice">
            {{invoice.paid_at | date: 'dd.MM.yyyy'}}
          </span>
            </div>
            <a class="download-invoice" [href]="invoice.download_url">
              <i class="fa-solid fa-download"></i>
              <span target="_blank">Download</span>
            </a>
          </div>
        </div>
        <div class="grid mobile-grid gird-gap-16" *ngIf="cardData !== undefined">
          <div class="grid__m-12">
            <div class="grid__m-12">
              <span class="desc-payment">You can cancel your subscription at any time. You'll continue to have access to your account until your current billing period ends.</span>
            </div>
            <div class="grid__m-12 subscriptions-buttons">
              <button *ngIf="subscriptionStatus.active && !subscriptionStatus.canceled" class="zc-btn zc-btn--gray" (click)="isCancelSubscriptionModalOpen = true">
                <span>Cancel Subscription</span>
              </button>
              <button *ngIf="subscriptionStatus.active && subscriptionStatus.canceled" class="zc-btn zc-btn--primary" (click)="isResumeSubscriptionModalOpen = true">
                <span>Resume Subscription</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<wx-cancel-subscription-modal
  *ngIf="isCancelSubscriptionModalOpen"
  (cancelModal)="isCancelSubscriptionModalOpen = false"
  (confirmModal)="onConfirmCancelSubscription()"
></wx-cancel-subscription-modal>

<wx-resume-subscription-modal
  *ngIf="isResumeSubscriptionModalOpen"
  (cancelModal)="isResumeSubscriptionModalOpen = false"
  (confirmModal)="onConfirmResumeSubscription()"
></wx-resume-subscription-modal>
